<template>
    <div>
    <a-button :type="type" :shape="shape" :icon="icon" class="bottompage" @click="onClick()"/>
    </div>
</template>

<script>
    export default {
        props: [
    'type','icon','shape'
  ],
  methods:{
      onClick(){
        this.$emit("on-click", 'click');     
      }
  }
    }
</script>

<style lang="less" scoped>
.bottompage{
    position: fixed;
    right: 30px;
    bottom: 20px;
    z-index: 10;
    width: 40px;
    height: 40px;
    cursor: pointer;
    padding-bottom: 5px;
}
</style>